import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {

  return {
    '@global': {
      '[data-locator="BannerSupergraphic"] + [data-locator="SharedMultiCardContent"]': {
        marginTop: pxToRem(68)
      }
    },
    wrapper: {
      position: 'relative',
      overflow: 'hidden',
    },
    bgImage: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    sgImage: {
      width: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      '& picture.bannerSGImage': {
        '& img': {
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          objectPosition: 'bottom right',
          objectFit: 'contain',

          '[dir="rtl"] &': {
            objectPosition: 'bottom left',
            [theme.breakpoints.down('md')]: {
              objectFit: 'contain',
              objectPosition: 'bottom',
            },
          },

          [theme.breakpoints.down('md')]: {
            objectFit: 'contain',
            objectPosition: 'bottom',
          },
        },
      },
    },
    ogImage: {
      width: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      '& picture.bannerOGImage': {
        '& img': {
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          objectPosition: 'bottom right',
          objectFit: 'contain',

          '[dir="rtl"] &': {
            objectPosition: 'bottom left',
            [theme.breakpoints.down('md')]: {
              objectFit: 'contain',
              objectPosition: 'bottom',
            },
          },

          [theme.breakpoints.down('md')]: {
            objectFit: 'contain',
            objectPosition: 'bottom',
          },
        },
      },
    },
    bannerTextArea: {
      position: 'relative',
      top: 0,
      width: '50%',
      height: 'calc(var(--vh-static) * 100)',
      minHeight: '750px',
      maxHeight: '820px',
      paddingTop: pxToRem(95),
      paddingBottom: pxToRem(85),
      ...theme.mixins.paddingLeft(pxToRem(90)),
      color: theme.mixins.White(),
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'column',

      '[lang="ru"] &': {
        minHeight: '856px',
        maxHeight: '856px',
      },

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        background: 'linear-gradient(to right, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.0) 100%)',
        '[dir="rtl"] &': {
          background: 'linear-gradient(to right, rgba(0,0,0,0.0) 0%,rgba(0,0,0,0.4) 100%)',
        },
      },

      '@media (min-width: 1440px) and (max-width: 1511px)': {
        width: '60%',
      },

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 'auto',
        minHeight: 'auto',
        paddingTop: pxToRem(37),
        paddingBottom: pxToRem(295),
        ...theme.mixins.paddingLeft(pxToRem(22)),
        ...theme.mixins.paddingRight(pxToRem(22)),
      },
    },

    heading: {
      ...theme.mixins.marginLeft(0),
      marginBottom: pxToRem(16),
      '@media (min-width: 1440px) and (max-width: 1511px)': {
        width: '90%',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: pxToRem(80),

      },

      '& h2': {
        ...theme.palette.common.ellipsis(2),
      },
    },

    bottomText: {
      width: '76%',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
    },

    descriptionText: {
      '& p': {
        ...theme.palette.common.ellipsis(6),
      },
      '& .richText': {
        '& a': {
          color: theme.palette.common.white,
        },
      },
    },

    smallTitleText: {
      marginBottom: pxToRem(23),
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(10),
      },
      '& h5': {
        ...theme.palette.common.ellipsis(1),
      },
    },

    uppercase: {
      textTransform: 'uppercase',
    },

    bannerCTA: {
      marginTop: pxToRem(58),
      [theme.breakpoints.down('md')]: {
        marginTop: pxToRem(18),
      },

      '& .btn-txt': {
        ...theme.palette.common.ellipsis(1),
      },
    },
  }

})

export default useStyles;
