import { isEmpty } from 'lodash';

function checkEmptyObject(obj) {
  if (isEmpty(obj)) return true;
  return isEmpty(
    Object.entries(obj)
      .map(([key, value]) => {
        if (isEmpty(value)) return true;
        if (value instanceof Object) return checkEmptyObject(value);
        return false;
      })
      .filter((b) => b === false)
  );
}

export default checkEmptyObject;